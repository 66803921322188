import React from "react";
import Footer from "../components/Footer";
import Navbaraboutus from "../components/Navbaraboutus";
import Sec101 from "../components/Sec101";
import "../styles/Aboutus.css";
import { CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function Aboutus() {

  const percentage = 90;

  const percentage2 = 90;

  const percentage3 = 60;

  const percentage4 = 95;


  return (
    <>
    <Navbaraboutus />

    
      

      {/* Section2 of Aboutus */}

      {/* <div className="container-fluid">
        <div className="col-lg-12 py-5 px-4">
          <div className="row">
            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <img
               src={require("../images/Ellipse.png")}
                alt=""
                className="img-fluid"
              ></img>
              <div className="sect2headingaboutus">Discover and process</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div> 

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <img
                src={require("../images/Ellipse.png")}
                alt=""
                className="img-fluid"
              />
              <div className="sect2headingaboutus">Discover solution</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <img
                src={require("../images/Ellipse.png")}
                alt=""
                className="img-fluid"
              />
              <div className="sect2headingaboutus">Award winning plan</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <img
                src={require("../images/Ellipse.png")}
                alt=""
                className="img-fluid"
              />
              <div className="sect2headingaboutus">Ground breaking breif</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div>
          </div>
        </div>
      </div> */}






{/* <div className="container-fluid">
        <div className="col-lg-12 py-5 px-4">

<div class="row d-flex justify-content-center mt-100">

<div class="col-lg">



    <div class="progress blue">
      <span class="progress-left">
                        <span class="progress-bar"></span>
      </span>
      <span class="progress-right">
                        <span class="progress-bar"></span>
      </span>
      <div class="progress-value">90%</div>
    </div>

    
      <div class="progress yellow">
        <span class="progress-left">
                        <span class="progress-bar"></span>
        </span>
        <span class="progress-right">
                        <span class="progress-bar"></span>
        </span>
        <div class="progress-value">60%</div>
      </div>
    
      <div class="progress red">
        <span class="progress-left">
                        <span class="progress-bar"></span>
        </span>
        <span class="progress-right">
                        <span class="progress-bar"></span>
        </span>
        <div class="progress-value">70%</div>
      </div>

      <div class="progress green">
        <span class="progress-left">
                        <span class="progress-bar"></span>
        </span>
        <span class="progress-right">
                        <span class="progress-bar"></span>
        </span>
        <div class="progress-value">95%</div>
      </div>


   
</div>
</div>
</div>
</div> */}

<div className="container-fluid">
        <div className="col-lg-12 py-5 px-4">
          <div className="row">
          <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
          <div style={{ width: 200, height: 200 }}>
    <CircularProgressbar  value={percentage} text={`${percentage}%`} />;
</div>
              <div className="sect2headingaboutus">Discover and process</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div> 

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
          <div style={{ width: 200, height: 200 }}>
    <CircularProgressbar value={percentage2} text={`${percentage2}%`} />;
</div>
              <div className="sect2headingaboutus">Discover and process</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div> 

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
          <div style={{ width: 200, height: 200 }}>
    <CircularProgressbar value={percentage3} text={`${percentage3}%`} />;
</div>
              <div className="sect2headingaboutus">Discover and process</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div> 

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-md col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
          <div style={{ width: 200, height: 200 }}>
    <CircularProgressbar value={percentage4} text={`${percentage4}%`} />;
</div>
              <div className="sect2headingaboutus">Discover and process</div>
              <div className="contaboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div> 
        </div>
        </div>
        </div>


{/* <div style={{ width: 200, height: 200 }}>
    <CircularProgressbar value={percentage2} text={`${percentage2}%`} />;
</div>

<div style={{ width: 200, height: 200 }}>
    <CircularProgressbar value={percentage3} text={`${percentage3}%`} />;
</div>

<div style={{ width: 200, height: 200 }}>
    <CircularProgressbar value={percentage4} text={`${percentage4}%`} />;
</div>
</div> */}










      {/* Section3 of Aboutus */}

      <div className="container sect3bcolor">
        <div className=" col-lg-12 pb-5">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-lg-start align-items-center col-lg-6">
              <div className="sect3aboutus text-lg-start text-center">
                Our development team members
              </div>

              <div className="sect3aboutus2 text-lg-start text-center">
                Lorem ipsum dolor sit amet, conse quat reprimique eu mel. Ne sed
                muner di ceret. Explicari moderat, cu aperiri qualisque sit,
                eros putant definitiones no erasmus sed.
              </div>
            </div>

            <div className="sect3aboutusimg col-lg-6 pt-lg-0 pt-4">
              <img
                src={require("../images/sect3img.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section4 of Aboutus */}

      <div className="container-fluid mt-4 sec4bgcolabus">
        <div className="col-lg-12 pt-5 px-4 pb-lg-5">
          <div className="row">
            <div className=" d-flex flex-column align-items-center col-lg col-12 numtxt ms-lg-4 px-md-4 pb-lg-0 pb-2">
            <div>412</div>
            <h3>DUPLEX</h3>
            </div>

            <div className=" d-flex flex-column align-items-center col-lg col-12 numtxt ms-lg-4 px-md-4 pb-lg-0 pb-2">
            <div>519</div>
            <h3>VILLAS</h3>
            </div>

            <div className=" d-flex flex-column align-items-center col-lg col-12 numtxt ms-lg-4 px-md-4 pb-lg-0 pb-2">
            <div>170</div>
            <h3>APARTMENTS</h3>
            </div>

            <div className=" d-flex flex-column align-items-center col-lg col-12 numtxt ms-lg-4 px-md-4 pb-lg-0 pb-2">
            <div>262</div>
            <h3>ROOMS</h3>
            </div>
          </div>
        </div>
      </div>

      {/* Section5 of Aboutus */}

      <div className="thumbnail">
        <div className="container">
          <div className="col-lg-9 mx-auto padthumbnail">
            <div className="playbutton mx-auto d-flex justify-content-center align-items-center">
              <img src={require("../images/pbutton.png")} alt="" className="" />
            </div>
          </div>
        </div>
      </div>

      {/* Section6 of Aboutus */}

      <div className="container-fluid">
        <div className="col-lg-12 py-5 px-4">
          <div className="row">
            <div class="d-flex flex-column justify-content-Center  col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="sect6aboutus">01</div>
              <div className="sect6headingaboutus">PLANNING STAGE</div>
              <div className="cont6aboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="sect6aboutus">02</div>
              <div className="sect6headingaboutus">DEVELOPMENT</div>
              <div className="cont6aboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="sect6aboutus">03</div>
              <div className="sect6headingaboutus">EXECUTION PHASE</div>
              <div className="cont6aboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="sect6aboutus">04</div>
              <div className="sect6headingaboutus">NEW WAY OF LIVING</div>
              <div className="cont6aboutus">
                Lorem ipsum dolor sit amet, facilis admodum officiis enim
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section7 of Aboutus */}
      <div className="container-fluid sect101bcolor">
        <div className=" col-lg-12 pt-3 pb-5">
          <div className="row align-items-center justify-content-center">
            <div className="d-flex flex-column justify-content-center pt-5 pb-3 align-items-lg-start align-items-center col-lg-6">
              <div className="s7h text-lg-start text-center">
                SCHEDULE A MEETING
              </div>

              <div className="s7sh text-lg-start text-center">
                Lorem ipsum dolor sit amet, conse quat reprimique eu mel. Ne sed
                muner di ceret. Explicari moderat, cu aperiri qualisque sit,
                eros putant definitiones no erasmus sed.
              </div>
            </div>

            <div className="dcontents col-lg-6 pt-lg-0 pt-4">
              <div className="btnborderaboutus btntxtaboutus pt-2 mt-4 mb-sm-3 mb-lg-0 text-center">
                Request
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section8 of Aboutus */}
      <div className="container-fluid bgsect8color">
        <div className="col-lg-12 py-5 px-4">
          <div className="sect8headingmain">MEET THE TEAM</div>
          <div className="sect8subheadingmain pb-3">
          Meet Our Expert Team
Members          </div>
          <div className="row">
            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 pe-lg-1 mx-lg-1 aboutsect2img">
              <img
                src={require("../images/testimonialPHolder.png")}
                alt=""
                className="img-fluid"
              />
              <div className="sect8headingaboutus pt-4">IVET HOUZE</div>
              <div className="sect8subheading pt-0">ARCHITECT ASSISTANT</div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 px-lg-1 mx-lg-1 aboutsect2img">
              <img
                src={require("../images/testimonialPHolder.png")}
                alt=""
                className="img-fluid"
              />
              <div className="sect8headingaboutus pt-4">IVET HOUZE</div>
              <div className="sect8subheading pt-0">ARCHITECT ASSISTANT</div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-md col-12 my-3 px-sm-4 ps-lg-1 mx-lg-1 aboutsect2img">
              <img
                src={require("../images/testimonialPHolder.png")}
                alt=""
                className="img-fluid"
              />
              <div className="sect8headingaboutus pt-4">IVET HOUZE</div>
              <div className="sect8subheading pt-0">ARCHITECT ASSISTANT</div>
            </div>
          </div>
        </div>
      </div>

      {/* Section9 of Aboutus */}

      <Footer />
    </>
  );
}

export default Aboutus;
