import React from "react";
import "../styles/Footer.css";
import { useNavigate } from "react-router-dom";

function Footer() {

  const navigate = useNavigate()

    function handleClick() {
        navigate('/')
    }

    function handleClick2() {
      navigate('/aboutus')
  }


  return (
    <>
      <section class="">
        <footer class="footcolor text-white pb-5">
          <div class="container ms-lg-0 pb-5 pt-5">
            <div class="row">
              <div class="col-lg-5 col-md-12 mb-4 mb-md-0 ps-lg-5  pe-lg-0">
                {/* <h5 class="text-uppercase">Footer Logo</h5> */}
                <img
                src={require("../images/Luxtower.png")}
                alt=""
                className="img-fluid"
              />

                <p className="footerlogosub pt-3">
                  Showcase all your properties in a truely modern manner
                </p>
              </div>

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0 pe-lg-4 ps-lg-0">
                <h5 class="text-uppercase mb-3 footerfont1">Contact</h5>

                <div class="foot">
                  <div className="footerfont2">124-442-2887</div>
                  <div className="footerfont2">124-442-2887</div>
                  <div className="footerfont2">fokkner@qode.com</div>
                  <div className="footerfont2">
                    Elizabeth St, London SW1W 9BE, UK
                  </div>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 mb-4 mb-md-0 ps-lg-0">
                <h5 class="text-uppercase mb-3 footerfont1">Useful links</h5>

                <div class="foot2">
                  <div onClick={handleClick} className="footerfont2">Home</div>
                  <div onClick={handleClick2} className="footerfont2">About us</div>
                  <div className="footerfont2">Property</div>
                  <div className="footerfont2">Blogs</div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
}

export default Footer;
