import React from "react";
import "../styles/Section9.css"

function Inputtype(props) {

    return(
        <input type={props.type} className="user" value={props.value} name={props.name} placeholder={props.placeholder}/>
    )
}

export default Inputtype;