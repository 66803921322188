import React from "react";
import "../styles/Aboutus.css"
import "../styles/Section9.css"

function Sec101(){
    return(
        <>
          <div className="container-fluid sect101bcolor">
        <div className=" col-lg-12 pb-5">
          <div className="row align-items-center justify-content-center">
            <div className="d-flex flex-column justify-content-center pt-5 pb-1 align-items-lg-start align-items-center col-lg-6">
              <div className="s7h text-lg-start text-center">
              SCHEDULE A MEETING
              </div>

              <div className="s7sh text-lg-start text-center">
                Lorem ipsum dolor sit amet, conse quat reprimique eu mel. Ne sed
                muner di ceret. Explicari moderat, cu aperiri qualisque sit,
                eros putant definitiones no erasmus sed.
              </div>
            </div>

            <div className="dcontents col-lg-6 pt-lg-0 pt-4">
            <div className="btnborderaboutus btntxtaboutus pt-1 mt-4 text-center">Send Request</div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default Sec101;