import React from "react";
import "../styles/Section5.css";

function Section5() {
  return (
    <>
      {/* <div className="container-fluid">

<div className="row">

    <div  className="col-lg-6 col-12 houses">
    </div>

    <div  className="col-lg-6 col-12 bg-primary map">
    </div>

</div>

</div> */}

      {/* <div className="container pt-5 pb-5">
        <div className="  d-lg-flex justify-content-around col-10 py-5">
          <div className="container col-lg-6 d-flex flex-column align-items-center justify-content-around py-3 houses">

          <div className="cont">
            APARTMENT
          </div>
          <div className="cont">
            SIMPLEX
          </div>
          <div className="cont">
            DUPLEX
          </div>
          <div className="cont">
            VILLA
          </div>
          <div className="cont">
            STUDIO
          </div>

          </div>


          <div className="d-flex col-lg-6 ms-lg-3 mt-4 ">

            <img src={require('../images/map.png')} alt="" className="img-fluid" />
          </div>
        </div>
      </div> */}

      
<div className="container sect3bcolor">
        <div className=" col-lg-12 py-5">
          <div className="row d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-center align-items-center col-lg-6 houses">
              <div className="cont text-center">
              APARTMENT
              </div>

              <div className="cont text-center">
              SIMPLEX
              </div>

              <div className="cont text-center">
              DUPLEX
              </div>

              <div className="cont text-center">
              VILLA
              </div>

              <div className="cont text-center">
              STUDIO
              </div>

            </div>

            <div className=" d-flex justify-content-center col-lg-6 ps-lg-5  pt-5">
              <img
                src={require("../images/map.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>


    </>
  );
}

export default Section5;
