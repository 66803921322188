import React from "react";
import Footer from "../components/Footer";
import Navbar1 from "../components/Navbar1";
import Sec101 from "../components/Sec101";
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Section5 from "../components/Section5";
import Section6 from "../components/Section6";
import Section7 from "../components/Section7";
import Section9 from "../components/Section9";
import Testimonials from "../components/Testimonials";
import Aboutus from "./Aboutus";

function Home() {
  return (
    <div>
        <Navbar1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        {/* <Section7 /> */}
        <Testimonials />
        <Section9 />
        <Footer />
            </div>
  );
}

export default Home;