import React from "react";
import "../styles/Section1.css";
import "../styles/Navbar.css"
import { useNavigate } from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import {useState, useEffect} from "react"

function Navbaraboutus(){



  const [show, setShow] = useState(false)

  useEffect(() => {

      window.addEventListener('scroll', () => {

          if (window.scrollY > 100) {
              setShow(true)
          }
          else {
              setShow(false)
          }
      })
  }, [])

  const navigate = useNavigate()
    function handleClick() {
        navigate('/')
    }

    return(
        <>


<nav class={`navbar fixed-top navbar-expand-md d-md-grid justify-content-center navbar-dark py-md-3 ${show && 'background'}`}>
      <div class="container">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse justify-content-center " id="navbarNav">
          <div class="mx-auto"></div>
          <ul class="navbar-nav">
            <li class="nav-item">
              <a onClick={handleClick} class="nav-link text-white pe-md-4 navtxt" href="#">Home</a>
            </li>
            <li class="nav-item">
              <a  class="nav-link text-white navtxt px-md-4" href="#">About</a>
            </li>
            <li class="nav-item pt-1">
              {/* <a class="nav-link text-white navtxt px-md-4" href="#">LUXTOWER</a> */}
              <img
                src={require("../images/Luxtower.png")}
                alt=""
                className="img-fluid"
              />
            </li>
            <li class="nav-item">
              <a class="nav-link text-white navtxt px-md-4" href="#">Properties</a>
            </li>
            <li class="nav-item">
              <HashLink class="nav-link text-white navtxt ps-md-4" to="#contact-us">Contact</HashLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    {/* <div
      class="banner-image w-100 vh-100 d-flex justify-content-center align-items-center"
    >
      <div class="content text-center">
        <h1 class="text-white">WEB ZONE</h1>
      </div>
    </div> */}

<div className="aboutusmainimg">
        <div className="container">
          <div className="col-lg-9 mx-auto padaboutus">
            <h1 className="headingaboutus">About us</h1>
            <p className="subheadingaboutus">
              INTRODUCING A NEW WAY OF LIVING UNIQUE FROM OTHERS
            </p>
          </div>
        </div>
      </div>


        </>
    )
}

export default Navbaraboutus;