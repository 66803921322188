import React from "react";
import "../styles/Section2.css";
import "../Fonts/Jomolhari/Jomolhari-Regular.ttf"

function Section2(){
    return(<>


<div className="container sect3bcolor">
        <div className=" col-lg-12 py-5 px-4">
          <div className="row">
            <div className="d-flex flex-column align-items-lg-start align-items-center col-lg-6">
              <div className="sect2bannerheading font-face-jl text-lg-start text-center">
              MODERN SPACES AND PREMINUM VILLAS DEVELOPMENT AND SALES AGENCY
              </div>

              <div className="sect2bannersubheading pt-3 text-lg-start text-center">
              WELCOME TO LUXTOWER RESIDENCE SHOWCASE
              </div>
            </div>

            <div className="sect3aboutusimg col-lg-6 pt-lg-0 pt-4">
              <img
                src={require("../images/sect2homeimg.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

    </>
)
}

export default Section2;