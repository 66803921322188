import React from "react";
import "../styles/Section6.css";

function Section6()
{
    return(<>
<img src={require('../images/sect6.png')} alt="" className="img-fluid" />
   
    
    </>)
}

export default Section6;