import React from "react";
import "../styles/Section9.css";
import Inputtype from "./Inputtype";

function Section9() {
  return (
    <>
      {/* <div className="container-fluid">

            <div className="col-lg-12 sect9height">

                <div className="row">

                    <div className="col-lg-6 col-12 sect9map">

                        <img src={require('../images/GoogleMapTA.png')} alt="" className="img-fluid"  />

                    </div>

                    <div className="d-flex flex-column align-items-center justify-content-around col-lg-6 col-12 sect9col">

                        <div className="s9font">Request A Call Back</div>
                        
                        <div class="form-outline">

  <Inputtype type="text" name="email" placeholder="Name"/>
</div>

<div class="form-outline">
<Inputtype type="email" name="email" placeholder="@gmail.com"/>
  
</div>

<div class="form-outline">
  <textarea class="form-control" id="textAreaExample" rows="4" className="user"></textarea>
  <label class="form-label" for="textAreaExample">Message</label>
</div>

                    </div>

                </div>

            </div>

        </div> */}

      <div className="container-fluid" id="contact-us">
        <div className=" col-lg-12">
          <div className="row">
            <div className=" col-lg-6 px-0">
              <img
                src={require("../images/GoogleMap.png")}
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center col-lg-6 sect9col">
              <div className="s9font pb-3 mt-5 text-center">Request A Call Back</div>

              <div className=" pb-3 pt-4 text-center"><Inputtype type="text" name="email" placeholder="Name"/></div>

              <div className=" pb-4 pt-2 text-center"><Inputtype type="email" name="email" placeholder="Email"/></div>

              <div className=" pb-4 pt-lg-4 text-center"><Inputtype type="text" name="email" placeholder="Message"/></div>

              <div className="btnborder btntxt pt-2 mb-5 mt-3 text-center">Send Request</div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section9;
