import React from "react";
import "../styles/Section3.css";

function Section3() {
  return (
    <>
      <div className="container-fluid sect3bgimage">
        <div className="col-lg-12 py-5 px-4">
          <div className="row">
            <div class="d-flex flex-column justify-content-Center align-items-center  col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img1"></div>
              <div className="content1 py-3">SMART LIVING</div>
              <div className="subcontent1">
                But indeed we both accuse them and lead them to be worthy of
                just hatred, who are present in the blanties of the present
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img002"></div>
              <div className="content1 py-3">ECO CONSTRUCTION</div>
              <div className="subcontent1">
                But indeed we both accuse them and lead them to be worthy of
                just hatred, who are present in the blanties of the present
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img003"></div>
              <div className="content1 py-3">HUMAN IN MIND</div>
              <div className="subcontent1">
                But indeed we both accuse them and lead them to be worthy of
                just hatred, who are present in the blanties of the present
              </div>
            </div>
          </div>
          <div className="row pt-lg-4">
            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img004"></div>
              <div className="content1 py-3">ATRACTIVE LOCATION</div>
              <div className="subcontent1">
                But indeed we both accuse them and lead them to be worthy of
                just hatred, who are present in the blanties of the present
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img005"></div>
              <div className="content1 py-3">MODERN TECHNOLOGY</div>
              <div className="subcontent1">
                But indeed we both accuse them and lead them to be worthy of
                just hatred, who are present in the blanties of the present
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img006"></div>
              <div className="content1 py-3">AWARDED REWARDS</div>
              <div className="subcontent1">
                But indeed we both accuse them and lead them to be worthy of
                just hatred, who are present in the blanties of the present
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section3;
