import React from "react";
import "../styles/Section4.css";
import "../Fonts/Jomolhari/Jomolhari-Regular.ttf";

function Section4() {
  return (
    <>
      {/* <div className="container-fluid">
        <div className="col-lg-12 py-5 px-4">
          <div className="row">
            <div class="d-flex flex-column justify-content-Center align-items-center  col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="content1 py-3">CHOOSE ANYTHING
YOU LIKE</div>
              <div className="subcontent1">
              WELCOME TO LUXTOWER
RESIDENCE SHOWCASE
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
            <img
                src={require("../images/sect6.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
            <img
                src={require("../images/sect6.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>



          <div className="row pt-lg-4">
            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img1"></div>
              <div className="content1 py-3">ATRACTIVE LOCATION</div>
              <div className="subcontent1">
              But indeed we both accuse them and
lead them to be worthy of just hatred, who
are present in the blanties of the present
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img1"></div>
              <div className="content1 py-3">MODERN TECHNOLOGY</div>
              <div className="subcontent1">
              But indeed we both accuse them and
lead them to be worthy of just hatred, who
are present in the blanties of the present
              </div>
            </div>

            <div class="d-flex flex-column justify-content-Center align-items-center col-lg col-12 my-3 px-sm-4 mx-lg-1 aboutsect2img">
              <div className="img1"></div>
              <div className="content1 py-3">AWARDED REWARDS</div>
              <div className="subcontent1">
              But indeed we both accuse them and
lead them to be worthy of just hatred, who
are present in the blanties of the present
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container sect3bcolor">
        <div className=" col-lg-12 pt-5">
          <div className="row">
            <div className="d-flex flex-column justify-content-center align-items-center col-lg">
              <div className="sect4headingmain text-lg-start text-center">
                CHOOSE ANYTHING YOU LIKE
              </div>

              <div className="sect4subheadingmain pt-2 text-lg-start text-center">
                WELCOME TO LUXTOWER RESIDENCE SHOWCASE
              </div>
            </div>

            <div className="sect3aboutusimg d-flex justify-content-center  col-lg pt-lg-0 pt-4">
              <img
                src={require("../images/1.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="sect3aboutusimg d-flex justify-content-center  col-lg pt-lg-0 pt-4">
              <img
                src={require("../images/2.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="row pt-lg-3">
            <div className="sect3aboutusimg d-flex justify-content-center  col-lg pt-lg-0 pt-4">
              <img
                src={require("../images/5.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="sect3aboutusimg d-flex justify-content-center  col-lg pt-lg-0 pt-4">
              <img
                src={require("../images/4.png")}
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="sect3aboutusimg d-flex justify-content-center  col-lg pt-lg-0 pt-4">
              <img
                src={require("../images/3.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      {/* 
<div class="container">
  <div class="row">
    <div class="col img01">
      1 of 3
    </div>
    <div class="col img01">
      2 of 3 (wider)
    </div>
    <div class="col img01">
      3 of 3
    </div>
  </div>
  <div class="row">
    <div class="col img01">
      1 of 3
    </div>
    <div class="col img01">
      2 of 3 (wider)
    </div>
    <div class="col img01">
      3 of 3
    </div>
  </div> */}
      {/* </div> */}
    </>
  );
}

export default Section4;
